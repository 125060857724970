import React from "react"
import { withTranslation } from 'react-i18next';

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = ({ t, i18n }) => (
  <Layout>
    <SEO title="Kontakt" />
    <section className="section" style={{ marginTop: "6rem" }}>
      <div className="container" >
        <p className="title is-1 is-family-secondary has-text-primary">
          {t("CONTACT")}
        </p>
        <p className="title is-3 is-family-secondary">
          {t("INTERNATIONAL_FREIGHT_FORWARDING")}
        </p>
        <div className="columns" style={{ paddingBottom: "4rem", borderBottom: "1px solid #818181" }}>
          <div className="column">
            <div className="content">
              <p className="title is-4 is-family-secondary">Joanna Dymel</p>
              <p>mob.: +48 797 408 467</p>
              <p>email: asia@fastigo.pl</p>
              <p className="has-text-primary has-text-weight-bold">ENGLISH</p>
            </div>
            <div className="content" style={{ marginTop: "3rem" }}>
              <p className="title is-4 is-family-secondary">Arkadiusz Musiał</p>
              <p>mob. +48 882 353 988</p>
              <p>email: arkadiusz.musial@fastigo.pl</p>
              <p className="has-text-primary has-text-weight-bold">
                ENGLISH, GREEK
              </p>
            </div>
          </div>
          <div className="column">
            <div className="content">
              <p className="title is-4 is-family-secondary">
                Malgorzata Masternakl
              </p>
              <p>mob.: +48 517 846 105</p>
              <p>email: malgosia@fastigo.pl</p>
              <p className="has-text-primary has-text-weight-bold">DEUTSCH</p>
            </div>
            <div className="content" style={{ marginTop: "3rem" }}>
              <p className="title is-4 is-family-secondary">
                Aleksandra Tutka (Oddział Rzeszów)
              </p>
              <p>mob. +48 730 866 084</p>
              <p>email: aleksandra.tutka@fastigo.pl</p>
              <p className="has-text-primary has-text-weight-bold">ENGLISH</p>
            </div>
          </div>
          <div className="column">
            <div className="content">
              <p className="title is-4 is-family-secondary">Joanna Kapitan</p>
              <p>mob +48 504 217 160</p>
              <p>email: joanna.kapitan@fastigo.pl</p>
              <p className="has-text-primary has-text-weight-bold">ENGLISH</p>
            </div>
          </div>
        </div>

        <div className="columns" style={{ padding: "4rem 0", borderBottom: "1px solid #818181"}}>
          <div className="column is-two-thirds">
            <p className="title is-3 is-family-secondary">{t("DOMESTIC_SHIPPING")}</p>
            <div className="columns is-mobile">
              <div className="column">
                <div className="content">
                  <p className="title is-4 is-family-secondary">
                    Bożena Wolańska
                  </p>
                  <p>mob. +48 882 353 980</p>
                  <p>email: bozena.wolanska@fastigo.pl</p>
                </div>
              </div>
              <div className="column">
                <div className="content">
                  <p className="title is-4 is-family-secondary">
                    Agnieszka Jachym
                  </p>
                  <p>mob.: +48 518 930 232</p>
                  <p>email: agnieszka@fastigo.pl</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <p className="title is-3 is-family-secondary">{t("BOOKKEEPING")}</p>
            <div className="columns">
              <div className="column">
                <div className="content">
                  <p className="title is-4 is-family-secondary">
                    Joanna Musiol 
                  </p>
                  <p>mob.: +48 504 083 994</p>
                  <p>email: joanna@fastigo.pl</p>
                </div>
              </div>
            </div>
          </div>
        </div>
         

        <div className="columns" style={{ padding: "4rem 0"}}>
          <div className="column is-two-thirds">
            <p className="title is-3 is-family-secondary">{t("MANAGEMENT_BOARD")}</p>
            <div className="columns is-mobile">
              <div className="column">
                <div className="content">
                  <p className="title is-4 is-family-secondary">
                    Sebastian Adamek
                  </p>
                  <p>email: sebastian@fastigo.pl</p>
                </div>
              </div>
              <div className="column">
                <div className="content">
                  <p className="title is-4 is-family-secondary">
                    Małgorzata Masternak
                  </p>
                  <p>email: malgosia@fastigo.pl</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default withTranslation("Contact")(ContactPage)
